<template lang="pug">
.licensing
    v-row
        v-spacer
        v-col(cols='6')
            v-card(light, flat)
                v-card-text
                    v-data-table.hoverable(:headers='headers', :items='licensingFees', sort-by='product_id',  @click:row='handleClick')
                        template(v-slot:item.has_limit='{value}')
                            v-checkbox(v-model='value', disabled)
                        template(v-slot:item.fee='{value}')
                            span {{ dti.formatCurrency(value) }}
                v-card-actions
                    v-spacer
                    v-btn.brand.white--text(@click='feeDialog = true') Add New
                v-overlay(absolute, :value='busy')
                    .text-h6 Please Wait...
                    v-progress-linear(indeterminate)
        v-spacer
    v-dialog(v-model='feeDialog', max-width='50vw')
        v-card
            v-card-title Add New
            v-card-text
                v-form(ref='form')
                    v-row
                        v-col
                            v-select(:items='licensingGroups', label='Group', v-model='editingFee.group', outlined, item-text='name', item-value='name')
                        v-col
                            v-text-field(label='Fee', v-model.number='editingFee.fee', outlined, prefix='$', type='number')
                        v-col
                            v-autocomplete(outlined, v-model='editingFee.product_id', :items='searchResults', :loading='isLoading', :search-input.sync='searchStr', item-text='product_id', item_value='product_id', label='Product ID', cache-items)
                    v-row
                        v-col
                            v-checkbox(label='Has Limit', v-model='editingFee.has_limit', outlined)
                        v-col(v-if='editingFee.has_limit')
                            v-text-field(type='number', label='limit', v-model.number='editingFee.limit', outlined)
            v-card-actions
                v-spacer
                v-btn(text, @click='feeDialog = false') Close
                v-btn(@click='deleteFee', color='red', v-if='!isNew') Delete
                v-btn(color='green', @click='saveFee') Save
</template>

<script>
import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'satbonds',
            message: '',
            busy: false,

            isNew: true,
            feeDialog: false,
            licensingFees: [],
            headers: [
                {
                    text: 'Group',
                    value: 'group'
                },
                {
                    text: 'Product ID',
                    value: 'product_id'
                },
                {
                    text: 'Fee',
                    value: 'fee'
                },
                {
                    text: 'Has Limit',
                    value: 'has_limit'
                },
                {
                    text: 'Limit',
                    value: 'limit'
                }
            ],
            searchStr: '',
            searchResults: [],
            isLoading: false,
            editingFee: {
                fee: 0,
                product_id: null,
                has_limit: false,
                limit: 1,
                id: null,
                group: null
            },
            licensingGroups: []
        };
    },
    methods: {
        getAllData () {
            this.socketEmit('getLicensingFees', null, (results) => {
                this.licensingFees = results.fees.filter(item => item.active);
                this.licensingGroups = results.groups.filter(item => item.active);
            });
        },
        async saveFee () {
            let fee = _.cloneDeep(this.editingFee);
            if (this.isNew) {
                fee.id = dti.makeuuid();
            }
            let result = await this.sendCommand({
                action: 'updateLicensingFee',
                parameters: fee
            });
            this.getAllData();
            this.feeDialog = false;
        },
        handleClick (rowData) {
            this.isNew = false;
            Object.assign(this.editingFee, rowData);
            this.searchStr = rowData.product_id;
            this.feeDialog = true;
        },
        async deleteFee () {
            let fee = _.cloneDeep(this.editingFee);
            fee.active = false;
            let result = await this.sendCommand({
                action: 'updateLicensingFee',
                parameters: fee
            });
            this.getAllData();
            this.feeDialog = false;
        }
    },
    computed: {
    },
    watch: {
        feeDialog (val) {
            if (!val) {
                this.$refs['form'].reset();
                this.isNew = true;
            }
        },
        searchStr (val) {
            this.isLoading = true;
            let vm = this;
            this.socketEmit('searchProductId', val, (results) => {
                vm.searchResults = results;
                vm.isLoading = false;
            });
        }
    },
    mounted () {
        this.getAllData();
        window.licensingvm = this;
    }
};
</script>

<style lang="scss" scoped>


</style>

<style lang="scss">

</style>
